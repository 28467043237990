import jumpCountry from 'public/src/pages/common/orderLogic/jumpCountry'
import { RoeCheckSite, RuCheckSite } from 'public/src/pages/common/orderLogic/config'
import orderCheckJumpMask from './orderCheckJumpMask'
import schttp from 'public/src/services/schttp'
const { langPath, SiteUID, IS_RW } = gbCommonInfo

export default {
  data () {
    return {
      retainOrder: {
        mask: false,
        order: {}
      }
    }
  },
  components: {
    orderCheckJumpMask
  },
  methods: {
    // 逆向操作限制主题站点
    checkJumpSite: jumpCountry.checkJumpSite,
    checkCountryJump: jumpCountry.checkCountryJump,
    async isSupportDistributionCountry (countryName) {
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/isSupportDistributionCountry/get',
        data: { countryName }
      })
      return res || {}
    },
    checkJump (country_id) {
      return this.checkCountryJump(country_id) || this.checkJumpSite(SiteUID)
    },
    async checkJumpCountry (country_name) {
      const res = await this.isSupportDistributionCountry(country_name)
      let result = res?.info?.res
      
      if (![0, 1].includes(result)) {
        // 没有配置或接口失败的情况
        const checkSite = await this.$refs['orderCheckJumpMask'].checkSite(country_name)
        result = 0
        if (checkSite.length) {
          const { siteUid } = checkSite[0]
          if (siteUid == SiteUID) {
            result = 1
          }
        }
      }
      return result
    },
    jumpMask (obj = {}) {
      // url是切站后跳转到不同域名下的当前站点的子路径
      const { result, shipping_country_name, url, shipping_country_id, options = {} } = obj
      if (IS_RW && (RoeCheckSite.includes(+shipping_country_id) || RuCheckSite.includes(+shipping_country_id))) {
        //
        // roe国家rw没有专门站点，从而给与提示限制
        this.$toast(this.language.SHEIN_KEY_PWA_20335, 3000)
      } else if (result == 0) {
        this.$refs['orderCheckJumpMask'].orderJumpCountry = shipping_country_name
        this.$refs['orderCheckJumpMask'].show = true
        this.$refs['orderCheckJumpMask'].jumpUrl = url
        this.$refs['orderCheckJumpMask'].options = options
      }
    },
    // 退货
    async jumpReturn (item, result) {
      const { billno, shipping_country_name, shipping_country_id } = item 
      
      if (result != 1) {
        return this.jumpMask({
          url: `/orders/return/${billno}?pf=listordetail`,
          result,
          shipping_country_name,
          shipping_country_id,
        })
      }

      this.$router.push(`${langPath}/orders/return/${billno}?pf=listordetail`)
    },
    // 已退货退款历史查询
    async jumpReturnRefundList (item) {
      const { billno, shipping_country_name, shipping_country_id } = item 
      const result = await this.checkSiteFn(item)
      if (result != 1) {
        return this.jumpMask({
          url: `/user/order_return/return_refund_list/${billno}`,
          result,
          shipping_country_name,
          shipping_country_id,
        })
      }

      this.$router.push(`${langPath}/user/order_return/return_refund_list/${billno}`)
    },
    // 已支付整单取消
    async jumpRefund (item) {
      const { billno, payment_method } = item
      // this.refundNotCodMaskLoading = false
      this.refundNotCodMask = false
      this.$router.push({ path: `${langPath}/user/orders/refund/${billno}?paymentMethod=${payment_method}&type=1` })
    },
    async showRelationGoodsList ({ billno, payment_method, cancelInfo = {} } = {}, { type, url }) {
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getRefundOrderInfo/query',
        data: {
          payment_method,
          billno,
          apply_scene: 1
        },
        headers: { blackbox: window._fmOpt?.__blackbox || '' }
      })

      this.cancelForm.relatedOrders = res?.info?.related_orders || []
      // 如果整单退关联商品为空(不包含自己订单的商品)
      let refundGoodsList = res?.info?.refund_goods || []
      let noCurBillnoList = refundGoodsList?.filter(item => item.billno != billno)
      if (noCurBillnoList?.length) {
        this.relationGoodsList = refundGoodsList
        this.relationOptions = Object.assign({}, this.relationOptions, {
          type, url, billno,
          cancelInfo: type == 1 ? { ...cancelInfo, refundGoodsList } : {}
        })
      } else {
        this.relationGoodsList = []
      }
      return {
        code: res?.code,
        tips: res?.tips,
        flag: noCurBillnoList?.length
      }
    },
    async showRefundOrderEvt ({ url, showRelationDialog } = {}) {
      if (showRelationDialog) {
        this.exposeCombinedRefundTip('1-20-1-53')
        this.showRelationDialog = true
        this.relationOptions = Object.assign({}, this.relationOptions, { url })
      } else {
        location.href = url
      }
    },
    exposeCombinedRefundTip (type, clickType) {
      let mapBillno = this.relationGoodsList?.map(item => item.billno)
      let mapGoodsId = this.relationGoodsList?.map(item => item.order_goods_id)
      let { billno } = this.relationOptions || {}
      let baseParams = {
        order_no: billno,
        relation_billno: mapBillno.join(','),
        relation_goods_id: mapGoodsId.join(','),
      }
      if (clickType) {
        baseParams = { ...baseParams, type: clickType }
      }
      daEventCenter.triggerNotice({
        daId: type,
        extraData: baseParams
      })
    },
    updateDialogEvt () {
      this.showRelationDialog = false
    },
    // 已支付单件取消退款
    async jumpRefundItem (item) {
      const { billno, shipping_country_name, shipping_country_id } = item 
      const result = await this.checkSiteFn(item)
      if (result != 1) {
        this.jumpMask({
          url: `/user/orders/detail/${billno}`,
          result,
          shipping_country_name,
          shipping_country_id,
        })
        return Promise.reject(result)
      }

      return Promise.resolve(result)
    },
    async checkSiteFn (item = {}) {
      let result = 1
      let { shipping_country_id, shipping_country_name } = item 
      if (this.checkJump(shipping_country_id)) {
        result = await this.checkJumpCountry(shipping_country_name)
      }
      // TW特殊处理
      if (['m'].includes(SiteUID) && [209].includes(+shipping_country_id)) return 1

      return result
    },
    // 未支付取消
    async handelCacncelBtnClick (order, type) {
      this.changeRootStatus && this.changeRootStatus({ loading: true })
      const { billno, payment_method, orderStatus, shipping_country_name, shipping_country_id, order_type } = order
      let url = `/user/orders/detail/${billno}`
      if (this.moduleName == 'orderList') {
        url = '/user/orders/list'
      }

      const result = await this.checkSiteFn(order)
      if (result != 1) {
        this.changeRootStatus && this.changeRootStatus({ loading: false })
        return this.jumpMask({
          url,
          result,
          shipping_country_name,
          shipping_country_id,
        })
      }

      let cancelInfo = {
        billno,
        payment: payment_method,
        status: orderStatus,
        type: payment_method == 'cod' ? type || 2 : this.cancelForm.type
      }
      if (![0, 12, 13].includes(+orderStatus)) {
        let refundGoodsInfo = await this.showRelationGoodsList({ billno, payment_method, cancelInfo }, {
          type: 1
        })
        this.changeRootStatus && this.changeRootStatus({ loading: false })
        if (refundGoodsInfo.code == '0') {
          if (refundGoodsInfo.flag) {
            this.showRelationDialog = true
            this.exposeCombinedRefundTip('1-20-1-53')
            return
          }
        } else {
          this.showMsg({
            type: 'err',
            msg: refundGoodsInfo?.tips || this.language.SHEIN_KEY_PWA_15948
          })
          return
        }
      }
      this.changeRootStatus && this.changeRootStatus({ loading: false })
      this.cancelForm.show = true
      this.cancelForm.payment = payment_method
      this.cancelForm.billno = billno
      this.cancelForm.status = orderStatus
      this.cancelForm.type = payment_method == 'cod' ? type || 2 : this.cancelForm.type
      this.cancelForm.order_type = order_type
      this.cancelForm.order = order
    }
  }
}
