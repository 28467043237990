import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { parseQueryString } from '@shein/common-function'

export default {
  data() {
    return {
      isShowOverTimeLimit: false,
      isShowSign: false,
      isShowOldSign: false,
      returnItemIsShow: false,
      returnItemBillno: '',
      module_name: 'OrderDetail', // OrderDetail or OrderList
      isShowReturnUnavailableModal: false,
      unavailableModal: false,
      abTestConfig: {},
      returnOrderInfo: {},
      returnMixinAbt: {},
      showExpirePop: false
    }
  },
  methods: {
    toggleReturnUnavailableModal() {
      this.isShowReturnUnavailableModal = !this.isShowReturnUnavailableModal
    },
    toggleUnavailableModal() {
      this.unavailableModal = !this.unavailableModal
    },
    checkSiteItem(orderInfo) {
      if (this.module_name == 'OrderDetail') {
        return this.$parent.checkSiteFn(orderInfo)
      } else {
        return this.checkSiteFn(orderInfo)
      }
    },
    async handleReturnItem(orderInfo, module_name, isReturnOff) {
      if (isReturnOff) {
        this.toggleReturnUnavailableModal()
        return
      }
      this.returnOrderInfo = orderInfo
      this.module_name = module_name
      this.returnItemBillno = orderInfo.billno
      const result = await this.checkSiteItem(orderInfo)
      if (result != 1) {
        return this.returnJump(result)
      }
      if (orderInfo.voluntaryReturnType == 2) {
        // 降级
        this.getRefundTip(orderInfo.shipping_country_id)
        return
      }
      const { over_time_limit: isOverTimeLimit, is_signed: isSigned } = orderInfo
      // 是否超退货时效
      if (Number(isOverTimeLimit)) {
        return this.toggleShowOverTimeLimit(true)
      }
      // 是否签收
      if (Number(isSigned) !== 1) {
        return this.toggleReturnItemSign('popup')
      }
      this.returnJump(result)
    },
    toggleReturnItemSign: function (action) {
      const gaAction = {
        popup: `${this.module_name}-PopUpAutoReturnTips`,
        no: `${this.module_name}-PopUpAutoReturnTips-ClickNo`,
        close: `${this.module_name}-ClosePopUpAutoReturnTips`,
        yes: `${this.module_name}-PopUpAutoReturnTips-ClickYes`
      }
      const saActivityName = {
        popup: 'expose_popup_auto_return_tips',
        no: 'click_popup_auto_return_tips_no',
        close: 'click_popup_auto_return_tips_close',
        yes: 'click_popup_auto_return_tips_yes'
      }

      sa(
        'send',
        {
          activity_name: saActivityName[action],
          activity_param: {
            order_id: this.returnItemBillno
          }
        },
        { beacon: 1 }
      )

      if (this.returnMixinAbt?.showDoubleCheck) {
        this.isShowSign = !this.isShowSign
      } else {
        this.isShowOldSign = !this.isShowOldSign
      }

      if (action === 'yes') {
        this.returnJump(1)
      }
    },
    returnJump(result) {
      if (this.module_name == 'OrderDetail') {
        this.$parent.jumpReturn(this.returnOrderInfo, result)
      } else {
        this.jumpReturn(this.returnOrderInfo, result)
      }
    },
    toggleShowOverTimeLimit(isAnalysis) {
      if (isAnalysis) {
        sa('send', {
          activity_name: this.isShowOverTimeLimit
            ? 'click_popup_non_returnable_ok'
            : 'expose_popup_non_returnable',
          activity_param: {}
        })
      }
      if (this.returnMixinAbt?.showDoubleCheck) {
        this.showExpirePop = !this.showExpirePop
        return
      }
      this.isShowOverTimeLimit = !this.isShowOverTimeLimit
    },
    /*
     *
     *非{Waiting for Payment(0,12)、Awaiting Verification(13)、pending(16)、paid(1)、Revoked(8)、Rejected(9)、canceled(3)、Refunded(11)、Expired(2)}时，查退货中心接口
     */
    getOrderReturn(orders) {
      let newOrders = []
      const data = []
      if (orders) {
        this.module_name = 'OrderList'
        newOrders = orders
      } else {
        this.module_name = 'OrderDetail'
        newOrders = [this.orderInfo]
      }

      newOrders.forEach(item => {
        if ([5, 7, 10, 18].includes(item.orderStatus)) {
          data.push({
            billno: item.billno
          })
        }
      })
      if (!data.length) {
        return
      }
      this.csasQueryOrderReturn(data)
    },
    async csasQueryOrderReturn(data) {
      const queryData = data?.map(item => item.billno)
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/return/checkOrderReturn/query',
        data: {
          billnos: queryData.join(',')
        }
      })
      if (res.code == 0) {
        const orders = (res.info && res.info.orderList) || []
        this.orders.forEach((item, index) => {
          orders.forEach(i => {
            if (item.billno == i?.billno) {
              this.$set(this.orders[index], 'returnable', i.returnable) // 订单是否可退 0:否 1:是
              this.$set(this.orders[index], 'over_time_limit', i.overTimeLimit) // 是否超退货时效 0:否 1:是
              this.$set(this.orders[index], 'is_signed', i.isReceived) // 是否已签收 0:否 1:是
              this.$set(this.orders[index], 'voluntaryReturnType', i.voluntaryReturnType) // 自主退货类型 0:停用 1:启用 2:降级
            }
          })
        })
        this.$nextTick(() => {
          this.adjuctOrderListMore()
          $(window).scroll()
        })
      }
    },
    async getRefundTip(shipping_country_id) {
      const submitData = {
        desc_type: [17],
        country_id: shipping_country_id
      }
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/return/getRefundTip/get',
        data: submitData
      })
      if (res.code == 0 && res.info) {
        const return_desc_data = res.info.return_desc_data || []
        return_desc_data.forEach(item => {
          if (item.desc_type == 17) {
            this.showMaskTips(item.refund_desc || '')
          }
        })
      }
    },
    setReturnMixinAbt(abtInfo) {
      this.returnMixinAbt = abtInfo
    }
  }
}
