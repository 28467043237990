<template>
  <div class="bin-coupons-block">
    <div
      v-if="isShowTxt"
      v-expose="{
        id: '1-11-1-219',
        data: binCouponType
      }"
      class="pay-bin-coupons"
    >
      <span
        class="txt txt-discount"
        :class="{ 'txt-normal-discount': normalPayData.showCard }"
      >
        {{ discountText }}
      </span>
      <span
        v-if="normalPayData.showCard"
        class="txt txt-normal-pay"
      >
        {{ normalPayData.cardName }}
      </span>
    </div>
    <span
      v-if="isShowTxt && isPayBinCouponsAdd"
      class="add-tip"
    >
      +
    </span>
  </div>
</template>

<script setup>
/**
 * @file 支付方式组件: 卡bin优惠券入口组件
 */
import { computed } from 'vue'
import { template } from '@shein/common-function'

const props = defineProps({
  scene: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => {}
  },
  cardBin: {
    type: String,
    default: ''
  },
  isPayBinCouponsAdd: {
    type: [Boolean, Number],
    default: false
  },
  payCode: {
    type: String,
    default: ''
  },
  couponList: {
    type: Array,
    default: () => []
  },
  orderInfo: {
    type: Object,
    default: () => {}
  }
})

const orderInfoCouponList = computed(() => {
  return props.orderInfo?.coupon_list || []
})

const supportBinData = computed(() => {
  const nowUseCoupon = props.couponList?.find(item => {
    return (
      item?.card?.card_name && // 卡 bin 优惠券
      (item?.paymentLimit?.length === 0 ||
        item?.paymentLimit?.includes(props.payCode)) // 有支付方式限制
    )
  })

  // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
  return nowUseCoupon
})

const isShowTxt = computed(() => {
  return !!supportBinData.value
})

const discount = computed(() => {
  return (
    orderInfoCouponList.value.find(
      item => item.coupon === supportBinData.value?.couponCode
    )?.coupon_price?.amountWithSymbol || ''
  )
})

const discountText = computed(() => {
  return `${template(discount.value, props.language.SHEIN_KEY_PWA_31382)}`
})

// 在非token支付的情况下，是否展示card 和  cardName取值
const normalPayData = computed(() => {
  let data = {
    showCard: false,
    cardName: ''
  }

  if (supportBinData.value) {
    data.showCard = true
    data.cardName = supportBinData.value?.card?.card_name
  }
  return data
})

const binCouponType = computed(() => {
  return {
    bincoupon_type: 1,
    payment_method: props.payCode
  }
})
</script>

<style scoped lang="less">
.bin-coupons-block {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-top: 0.1rem;
  margin-bottom: 6/75rem;
  .pay-bin-coupons {
    display: flex;
    align-items: center;
    margin-bottom: unset;
    border: 1/75rem #ffe2cf solid;
    border-radius: 4/75rem;
    background-color: #fff6f3;
    padding: 0.05333333rem 15/75rem;
    position: relative;
    color: var(---sui_color_discount_dark, #c44a01);
    font-size: 10/37.5rem;
    font-family: SF UI Text;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1;
    max-width: 100%;

    // 超出一行展示省略号
    .txt {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .txt-discount {
      max-width: 96%;
      margin-right: 4/37.5rem;
    }
    /deep/.txt-normal-discount {
      max-width: 220/37.5rem;
    }
    .txt-normal-pay {
      flex: 1;
      position: relative;
      padding-left: 4/37.5rem;
      margin-right: 4/37.5rem;
      font-weight: 400;
      &::before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 20%;
        left: 0;
        width: 1/75rem;
        height: 70%;
        border-left: 1/75rem solid var(---sui_color_discount_dark, #c44a01);
      }
    }
    .txt-small {
      font-weight: 400;
    }
  }

  .add-tip {
    margin: 0 8/75rem;
  }
}
.bin-coupons-block.not-last {
  .pay-bin-coupons {
    max-width: 96%;
  }
}
.payment-options-item__coupons_txt.not-last {
  margin-bottom: -0.05rem;
}
.pay-item-token__bin_coupons.not-last {
  margin-bottom: unset;
}
.discount-tips__coupons_txt {
  margin-bottom: 0;
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 7/75rem;
  height: 14/75rem;
  border: 1/75rem solid #ffe2cf;
  transform: translateY(-50%);
  background-color: white;
}

.pay-bin-coupons::before {
  .coupons-before-after();
  left: -2/75rem;
  border-left-color: #fff;
  border-radius: 0 7/75rem 7/75rem 0;
}

.pay-bin-coupons::after {
  .coupons-before-after();
  right: -2/75rem;
  border-right-color: #fff;
  border-radius: 7/75rem 0 0 7/75rem;
}
</style>
